import * as React from "react";
import { useState } from "react";
import {
  PageHeader,
  Nav,
  NavList,
  NavItem,
  Divider,
  Dropdown,
  DropdownGroup,
  DropdownItem,
  DropdownToggle,
  PageHeaderTools,
  Toolbar,
  ToolbarContent,
} from "@patternfly/react-core";
import "@rhds/elements/rh-navigation-secondary/rh-navigation-secondary.js";
import "@rhds/elements/rh-navigation-secondary/rh-navigation-secondary-lightdom.css";

const summitIconUrl =
  "https://www.redhat.com/themes/custom/rh_base_ui/dist/img/red-hat-summit-logo.svg";

const HeaderTools = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const onToggle = (isOpen) => setIsDropdownOpen(isOpen);
  const onSelect = () => setIsDropdownOpen(false);

  const summitImg = (
    <a
      href="https://www.redhat.com/en/summit"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={summitIconUrl}
        alt="Red Hat Summit"
        style={{
          margin: "auto",
          width: "48px",
          height: "auto",
          boxSizing: "content-box",
        }}
      />
    </a>
  );

  const headerToolLinks = [
    {
      description: "Support",
      to: "https://access.redhat.com/",
    },
    {
      description: "Documentation",
      to: "https://docs.redhat.com/en",
    },
    {
      description: "Console",
      to: "https://cloud.redhat.com/",
    },
    {
      description: "Developers",
      to: "https://developers.redhat.com/",
    },
    {
      description: "Start a trial",
      to: "https://www.redhat.com/en/products/trials",
    },
  ];

  const dropdownItems = [
    <DropdownGroup
      key="customer"
      label="For customers"
      className="dropdown-column"
    >
      <DropdownItem key="Customer support">
        <a
          href="https://access.redhat.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Customer support
        </a>
      </DropdownItem>
      <DropdownItem key="Subscription management">
        <a
          href="https://access.redhat.com/management"
          target="_blank"
          rel="noopener noreferrer"
        >
          Subscription management
        </a>
      </DropdownItem>
      <DropdownItem key="Support cases">
        <a
          href="https://access.redhat.com/support/cases"
          target="_blank"
          rel="noopener noreferrer"
        >
          Support cases
        </a>
      </DropdownItem>
      <DropdownItem key="Red Hat Ecosystem Catalog">
        <a
          href="https://catalog.redhat.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Red Hat Ecosystem Catalog
        </a>
      </DropdownItem>
      <DropdownItem key="Find a partner">
        <a
          href="https://catalog.redhat.com/partners"
          target="_blank"
          rel="noopener noreferrer"
        >
          Find a partner
        </a>
      </DropdownItem>
    </DropdownGroup>,
    <DropdownGroup
      key="learning"
      label="Learning resources"
      className="dropdown-column"
    >
      <DropdownItem key="Training and certification">
        <a
          href="https://www.redhat.com/en/services/training-and-certification"
          target="_blank"
          rel="noopener noreferrer"
        >
          Training and certification
        </a>
      </DropdownItem>
      <DropdownItem key="Hybrid cloud learning hub">
        <a
          href="https://cloud.redhat.com/learn"
          target="_blank"
          rel="noopener noreferrer"
        >
          Hybrid cloud learning hub
        </a>
      </DropdownItem>
      <DropdownItem key="Interactive labs">
        <a
          href="https://www.redhat.com/en/interactive-labs"
          target="_blank"
          rel="noopener noreferrer"
        >
          Interactive labs
        </a>
      </DropdownItem>
      <DropdownItem key="Learning community">
        <a
          href="https://learn.redhat.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learning community
        </a>
      </DropdownItem>
      <DropdownItem key="Red Hat TV">
        <a
          href="https://tv.redhat.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Red Hat TV
        </a>
      </DropdownItem>
      <DropdownItem key="Architecture center">
        <a
          href="https://www.redhat.com/architect/portfolio/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Architecture center
        </a>
      </DropdownItem>
    </DropdownGroup>,
    <DropdownGroup
      key="partners"
      label="For partners"
      className="dropdown-column"
    >
      <DropdownItem key="Partner portal">
        <a
          href="https://connect.redhat.com/partner-admin/dashboard"
          target="_blank"
          rel="noopener noreferrer"
        >
          Partner portal
        </a>
      </DropdownItem>
      <DropdownItem key="Partner support">
        <a
          href="https://connect.redhat.com/en/support"
          target="_blank"
          rel="noopener noreferrer"
        >
          Partner support
        </a>
      </DropdownItem>
      <DropdownItem key="Become a partner">
        <a
          href="https://connect.redhat.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Become a partner
        </a>
      </DropdownItem>
    </DropdownGroup>,
    <DropdownGroup
      key="opensource"
      label="Open source communities"
      className="dropdown-column"
    >
      <DropdownItem key="Global advocacy">
        <a
          href="https://access.redhat.com/accelerators"
          target="_blank"
          rel="noopener noreferrer"
        >
          Global advocacy
        </a>
      </DropdownItem>
      <DropdownItem key="How we contribute">
        <a
          href="https://www.redhat.com/en/about/our-community-contributions"
          target="_blank"
          rel="noopener noreferrer"
        >
          How we contribute
        </a>
      </DropdownItem>
    </DropdownGroup>,
    <DropdownGroup
      key="try-buy"
      label="Try, buy, & sell"
      className="dropdown-column"
    >
      <DropdownItem key="Red Hat Marketplace">
        <a
          href="https://marketplace.redhat.com/en-us"
          target="_blank"
          rel="noopener noreferrer"
        >
          Red Hat Marketplace
        </a>
      </DropdownItem>
      <DropdownItem key="Red Hat Store">
        <a
          href="https://www.redhat.com/en/store"
          target="_blank"
          rel="noopener noreferrer"
        >
          Red Hat Store
        </a>
      </DropdownItem>
      <DropdownItem key="Contact sales">
        <a
          href="https://www.redhat.com/en/contact"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact sales
        </a>
      </DropdownItem>
      <DropdownItem key="Start a trial">
        <a
          href="https://www.redhat.com/en/products/trials"
          target="_blank"
          rel="noopener noreferrer"
        >
          Start a trial
        </a>
      </DropdownItem>
    </DropdownGroup>,
  ];

  return (
    <PageHeaderTools>
      <Nav
        variant="horizontal"
        aria-label="Horizontal nav for Red Hat resources"
      >
        <NavList>
          {/* Summit Icon as its own NavItem */}
          <NavItem key="summit" children={summitImg} />
          <Divider
            orientation={{
              default: "vertical",
            }}
          />

          {headerToolLinks.map((item, index) => (
            <>
              <NavItem key={index} to={item.to}>
                {item.description}
              </NavItem>
              <Divider
                orientation={{
                  default: "vertical",
                }}
              />
            </>
          ))}
        </NavList>
      </Nav>
      <Toolbar
        id="all-redhat-toolbar-content"
        className={isDropdownOpen ? "toolbar-expanded" : ""}
      >
        <ToolbarContent style={{ paddingRight: 0 }}>
          <Dropdown
            id="all-red-hat-dropdown"
            isPlain
            onSelect={onSelect}
            isOpen={isDropdownOpen}
            toggle={
              <DropdownToggle
                onToggle={onToggle}
                className="pf-c-dropdown__toggle"
              >
                All Red Hat
              </DropdownToggle>
            }
            dropdownItems={dropdownItems}
            className="two-column-dropdown"
          />
        </ToolbarContent>
      </Toolbar>
    </PageHeaderTools>
  );
};

const logoImg = (
  <a href="https://www.redhat.com" target="_blank" rel="noopener noreferrer">
    <img
      src="https://www.redhat.com/architect/portfolio/images/logo_redhat.png"
      width="135"
      height="40"
      alt="Red Hat Official Logo"
    />
  </a>
);

const menuContent = (
  <Nav variant="horizontal">
    <NavList>
      <NavItem key={0} itemId={0} isActive={false} href="/">
        <a href="https://www.redhat.com/en/technologies/all-products">
          Products
        </a>
      </NavItem>
      <NavItem key="Solutions" itemId="Solutions" isActive={false} href="/">
        <a href="https://www.redhat.com/en/solutions">Solutions</a>
      </NavItem>
      <NavItem
        key="Training & services"
        itemId="Training & services"
        isActive={false}
        href="/"
      >
        <a href="https://www.redhat.com/en/services">Training & services</a>
      </NavItem>
      <NavItem key="Resources" itemId="Resources" isActive={false} href="/">
        <a href="https://www.redhat.com/en/resources">Resources</a>
      </NavItem>
      <NavItem key="Partners" itemId="Partners" isActive={false} href="/">
        <a href="https://www.redhat.com/en/partners">Partners</a>
      </NavItem>
      <NavItem key="About" itemId="About" isActive={false} href="/">
        <a href="https://www.redhat.com/en/about">About</a>
      </NavItem>
    </NavList>
  </Nav>
);

const header = (
  <PageHeader
    id="primary-header"
    headerTools={<HeaderTools />}
    logo={logoImg}
    topNav={menuContent}
  />
);

const secondaryHeader = (
  <rh-navigation-secondary
    role="navigation"
    color-palette="light"
    style={{ boxShadow: "none" }}
  >
    <a href="#" slot="logo">
      Red Hat <br></br>Architecture Center
    </a>
    <ul slot="nav">
      <li>
        <a href="/architect/portfolio/">Catalog</a>
      </li>
      <li>
        <a href="/architect/portfolio/learnmore">Learn</a>
      </li>
      <li>
        <a
          href="https://www.redhat.com/architect/portfolio/tool/index.html"
          target="_blank"
        >
          Diagramming Tool
        </a>
      </li>
    </ul>
  </rh-navigation-secondary>
);

export { header, secondaryHeader };
