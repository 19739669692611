import * as React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Page, SkipToContent } from "@patternfly/react-core";
import { header, secondaryHeader } from "./Header";

interface IAppLayout {
  children: React.ReactNode;
}

const AppLayout: React.FunctionComponent<IAppLayout> = ({ children }) => {
  const [isMobileView, setIsMobileView] = React.useState(true);

  const onPageResize = (props: { mobileView: boolean; windowSize: number }) => {
    setIsMobileView(props.mobileView);
  };

  function sendCustomEvent(evt: any) {
    if (document.createEvent && document.body.dispatchEvent) {
      var myEvent = document.createEvent("Event");
      myEvent.initEvent(evt, true, true); //can bubble, and is cancellable

      document.body.dispatchEvent(myEvent);
      console.log("New Event dispatched:[" + myEvent + "]");
      //@ts-ignore
    } else if (window.CustomEvent && document.body.dispatchEvent) {
      //@ts-ignore
      var event = new CustomEvent(evt, { bubbles: true, cancelable: true });

      document.body.dispatchEvent(event);
      console.log("CustomEvent dispatched:[" + event + "]");
    }
  }

  const location = useLocation();
  useEffect(() => {
    // Call sendCustomEvent function when a new page is loaded:
    sendCustomEvent("pageBottom");
  }, [location]);

  const pageId = "primary-app-container";

  const PageSkipToContent = (
    <SkipToContent
      onClick={(event) => {
        event.preventDefault();
        const primaryContentContainer = document.getElementById(pageId);
        primaryContentContainer && primaryContentContainer.focus();
      }}
      href={`#${pageId}`}
    >
      Skip to Content
    </SkipToContent>
  );

  return (
    <Page
      mainContainerId={pageId}
      header={header}
      onPageResize={onPageResize}
      skipToContent={PageSkipToContent}
    >
      {secondaryHeader}
      {children}
    </Page>
  );
};

export { AppLayout };
