import React from "react";

import {
  Banner,
  Masthead,
  MastheadMain,
  MastheadBrand,
  MastheadContent,
  Button,
  Panel,
  PanelMain,
  PanelMainBody,
  PanelFooter,
  Page,
  Tile,
} from "@patternfly/react-core";
import ArrowRightIcon from "@patternfly/react-icons/dist/esm/icons/arrow-right-icon";
import ArrowLeftIcon from "@patternfly/react-icons/dist/esm/icons/arrow-left-icon";
import { Carousel } from "@trendyol-js/react-carousel";
import announcementList from "./AnnouncementList.csv";
import Papa from "papaparse";

class PABanner extends React.Component {
  displayList;
  loadAnnouncementList = () =>
    Papa.parse(announcementList, {
      header: true,
      complete: (results) => {
        this.displayList = results.data;
      },
    });

  constructor(props) {
    super(props);
    this.state = {
      isMobileView: false,
    };
  }
  async componentDidMount() {}

  render() {
    this.loadAnnouncementList();

    if (!Array.isArray(this.displayList)) {
      this.displayList = [] as any;
      alert("cant load file");
    }

    const onPageResize = (props: {
      mobileView: boolean;
      windowSize: number;
    }) => {
      this.setState({ isMobileView: props.mobileView });
    };
    var learnMoreButton = (
      <span class="banner_link_learn_more">
        <Button variant="link">
          <img
            width="200"
            height="25"
            src="/architect/portfolio/images/contribute_schematic1.png"
            alt="Learn More"
          ></img>
          <br />
          <a href="/architect/portfolio/learnmore">
            Learn More <ArrowRightIcon />
          </a>
        </Button>
      </span>
    );
    if (this.state.isMobileView === true) {
      learnMoreButton = <div></div>;
    }

    return (
      <React.Fragment>
        <Page onPageResize={onPageResize} className="banner_page">
          <Masthead id="light-masthead" backgroundColor="light">
            <MastheadMain>
              <MastheadBrand>
                <img
                  src="https://www.redhat.com/architect/portfolio/images/site-logo.png"
                  width="230"
                  alt="Red Hat Architecture Center Logo"
                />
              </MastheadBrand>
            </MastheadMain>
            <MastheadContent>
              <Panel>
                <PanelMain>
                  <PanelMainBody className="_banner">
                    {" "}
                    The Red Hat Architecture Center showcases successful
                    deployments of Red Hat’s and our partners’ software, as well
                    as provides architecture best practices, tools, and links to
                    other associated resources to meet your innovative business
                    objectives. &nbsp;{" "}
                    <a href="/architect/portfolio/learnmore">
                      Learn More <ArrowRightIcon />
                    </a>
                  </PanelMainBody>
                </PanelMain>
              </Panel>
            </MastheadContent>
          </Masthead>
          <Masthead id="light-masthead-carousel" backgroundColor="light">
            <MastheadContent>
            <Carousel
                  show={1}
                  slide={1}
                  swiping={true}
                  infinite={true}
                  leftArrow={
                    <button className="custom-arrow left-arrow">
                      <ArrowLeftIcon />
                    </button>
                  }
                  rightArrow={
                    <button className="custom-arrow right-arrow">
                      <ArrowRightIcon />
                    </button>
                  }
                >
                
                {this.displayList.map((item) => (
                  <div class="admonitionblock announcement">
                    <table class="banner">
                      <tbody>
                        <tr>
                          <td class="icon">
                            <div class="title">{item.announcementType}</div>
                          </td>
                          <td class="content">
                            <a href={item.titleLink}>
                              <i class="pf-icon pf-icon-attention-bell" />
                              &nbsp;&nbsp; <b>{item.title}</b>
                            </a>{" "}
                            &nbsp; &nbsp; <i>{item.date}</i> &nbsp; &nbsp;{" "}
                            {item.desc}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ))}
              </Carousel>
            </MastheadContent>
          </Masthead>
        </Page>
      </React.Fragment>
    );
  }
}

export default PABanner;
