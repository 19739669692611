import React from "react";
import listfile from "./PAList.csv";
import Papa from "papaparse";

export const SelectedList = React.createContext();

// Define the analytics object globally or at the top of the file
const analytics = {
  eVar41: "",
  events: "",
  tl: (linkType, linkName, eventName) => {
    console.log(`Tracking: ${eventName}, Term: ${analytics.eVar41}`);
    // Replace this with your Adobe Analytics function if applicable
    if (window.s) {
      window.s.linkTrackVars = "eVar41,events";
      window.s.linkTrackEvents = "event18";
      window.s.eVar41 = analytics.eVar41;
      window.s.events = analytics.events;
      window.s.tl(true, linkType, linkName);
    }
  },
};

class SelectedListProvider extends React.Component {
  loadlist = () =>
    Papa.parse(listfile, {
      header: true,
      complete: (results) => {
        return results.data;
      },
    });

  loadLive = () => {
    var livelist = [];
    this.loadlist().data.forEach((element) => {
      if (element.islive === "TRUE") {
        livelist.push(element);
      }
    });
    return livelist;
  };

  state = {
    currentlist: this.loadLive(),
    selectedProduct: [],
    selectedPlatform: [],
    selectedSolution: [],
    selectedVertical: [],
    selectedProductType: [],
    searchInput: "",
  };

  updateList = () => {
    let templist = this.loadLive();

    if (this.state.selectedProduct.length > 0) {
      templist = templist.filter((element) =>
        this.state.selectedProduct.some((x) => element.Product.includes(x))
      );
    }

    if (this.state.selectedPlatform.length > 0) {
      templist = templist.filter((element) =>
        this.state.selectedPlatform.some((x) => element.Platform.includes(x))
      );
    }

    if (this.state.selectedSolution.length > 0) {
      templist = templist.filter((element) =>
        this.state.selectedSolution.some((x) => element.Solutions.includes(x))
      );
    }

    if (this.state.selectedVertical.length > 0) {
      templist = templist.filter((element) =>
        this.state.selectedVertical.some((x) => element.Vertical.includes(x))
      );
    }

    if (this.state.selectedProductType.length > 0) {
      templist = templist.filter((element) =>
        this.state.selectedProductType.some((x) =>
          element.ProductType.includes(x)
        )
      );
    }

    if (this.state.searchInput !== "") {
      templist = this.filterSearchResults(templist);
    }

    this.setState({ currentlist: templist });
  };

  searchAll = (searchInput) => {
    // Update state and filtered list
    this.setState({ searchInput }, () => {
      this.updateList();
    });

    // Adobe Analytics integration
    if (searchInput.trim() !== "") {
      analytics.eVar41 = searchInput.trim(); // Assign search term
      analytics.events = "event18"; // Set a search-specific event
      analytics.tl("o", "Search Interaction", "Search Term"); // Trigger tracking
    } else {
      // Clear tracking data if search input is empty
      analytics.eVar41 = "";
      analytics.events = "";
    }

    // Update URL with the search term
    const newUrl = searchInput.trim()
      ? `${window.location.pathname}?search=${encodeURIComponent(searchInput)}`
      : window.location.pathname;
    window.history.replaceState(null, "", newUrl);
  };

  filterSearchResults = (templist) => {
    const searchInput = this.state.searchInput.trim();
    const isExactMatch =
      searchInput.startsWith('"') && searchInput.endsWith('"');
    const phrase = isExactMatch
      ? searchInput.slice(1, -1).toLowerCase()
      : searchInput.toLowerCase();
    const searchTerms = isExactMatch ? [phrase] : phrase.split(/\s+/);

    return templist.filter((element) => {
      const fieldsToSearch = [
        element.Heading,
        element.Summary,
        element.Product,
        element.Platform,
        element.Solutions,
        element.Vertical,
        element.ProductType,
      ];

      const fields = fieldsToSearch.map((field) =>
        field?.toString().toLowerCase() || ""
      );
      const matchesExactPhrase =
        isExactMatch && fields.some((field) => field.includes(phrase));
      if (matchesExactPhrase) return true;

      const matchesAllTerms =
        !isExactMatch &&
        searchTerms.every((term) =>
          fields.some((field) => field.includes(term))
        );
      return matchesAllTerms;
    });
  };

  updateProduct = (pid) => {
    let selectedProduct = [...this.state.selectedProduct];
    if (!selectedProduct.includes(pid)) {
      selectedProduct.push(pid);
    } else {
      const rindex = selectedProduct.indexOf(pid);
      selectedProduct.splice(rindex, 1);
    }
    this.setState({ selectedProduct }, () => {
      this.updateList();
    });
  };

  updatePlatform = (plid) => {
    let selectedPlatform = [...this.state.selectedPlatform];
    if (!selectedPlatform.includes(plid)) {
      selectedPlatform.push(plid);
    } else {
      const rindex = selectedPlatform.indexOf(plid);
      selectedPlatform.splice(rindex, 1);
    }
    this.setState({ selectedPlatform }, () => {
      this.updateList();
    });
  };

  updateSolution = (sid) => {
    if (!this.state.selectedSolution.includes(sid)) {
      this.state.selectedSolution.push(sid);
    } else {
      const rindex = this.state.selectedSolution.indexOf(sid);
      this.state.selectedSolution.splice(rindex, 1);
    }
    this.setState({ selectedSolution: this.state.selectedSolution });
    this.updateList();
  };

  updateVertical = (vid) => {
    if (!this.state.selectedVertical.includes(vid)) {
      this.state.selectedVertical.push(vid);
    } else {
      const rindex = this.state.selectedVertical.indexOf(vid);
      this.state.selectedVertical.splice(rindex, 1);
    }
    this.setState({ selectedVertical: this.state.selectedVertical });
    this.updateList();
  };

  updateProductType = (tid) => {
    let selectedProductType = [...this.state.selectedProductType];
    if (!selectedProductType.includes(tid)) {
      selectedProductType.push(tid);
    } else {
      const rindex = selectedProductType.indexOf(tid);
      selectedProductType.splice(rindex, 1);
    }
    this.setState({ selectedProductType }, () => {
      this.updateList();
    });
  };

  render() {
    return (
      <SelectedList.Provider
        value={{
          ...this.state,
          updateProduct: this.updateProduct,
          updatePlatform: this.updatePlatform,
          updateSolution: this.updateSolution,
          updateVertical: this.updateVertical,
          updateProductType: this.updateProductType,
          searchAll: this.searchAll,
        }}
      >
        {this.props.children}
      </SelectedList.Provider>
    );
  }
}

export default SelectedListProvider;
