import ReactDOM from 'react-dom';
import "@patternfly/react-core/dist/styles/base.css";
import { Pagination, PaginationVariant } from '@patternfly/react-core';

import React from 'react';
import {
  Brand,
  Badge,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderMain,
  CardTitle,
  LabelGroup,
  Gallery,
  GalleryItem,
  Label
} from '@patternfly/react-core';
import { Link } from "react-router-dom";
import { SelectedList } from './SelectedList';

const CARD_IMG_URL = "/architect/portfolio/repo/images/";
const DETAIL_URL = '/detail';

class PACatalog extends React.Component {
  static contextType = SelectedList;

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      perPage: 12, // Default items per page
      isMobileView: false // To handle mobile view
    };
  }

  componentDidMount() {
    this.adjustTextClamp();
    window.addEventListener("resize", this.adjustTextClamp);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.adjustTextClamp);
  }

  adjustTextClamp = () => {
    const cardBody = document.querySelectorAll(".catalog_card_body");
    const footers = document.querySelectorAll(".catalog_card_footer");

    cardBody.forEach((body, index) => {
      const footer = footers[index];
      if (footer && footer.offsetHeight > 50) { // If tags overflow, reduce text lines
        body.classList.add("hide-extra-text");
      } else {
        body.classList.remove("hide-extra-text");
      }
    });
  };

  // Function to check if the view is mobile
  updateView = () => {
    const isMobileView = window.innerWidth < 768; // Mobile if width is less than 768px
    this.setState({
      isMobileView,
      perPage: isMobileView ? 3 : 10 // Show 3 items per page on mobile, 10 on desktop
    });
  };

  // Handle page change
  onPageChange = (_event, pageNumber) => {
    this.setState({ page: pageNumber });
  };

  // Handle per page change
  onPerPageChange = (_event, perPage) => {
    this.setState({ perPage, page: 1 }); // Reset to the first page after changing perPage
  };
  
  // Handle per page change
  onPerPageSelect = (_event, perPage) => {
    this.setState({ perPage, page: 1 }); // Reset to the first page after changing perPage
  };

  displayVertical(vertical) {
    if (vertical == "All") {
      return "All verticals";
    }
    return vertical;
  }

  displayPlatformTag(platform) {
    let platformUrl;
    if (platform === "AWS") {
      platformUrl = "https://www.redhat.com/en/partners/aws/red-hat-on-aws";
    } else if (platform === "Azure") {
      platformUrl = "https://www.redhat.com/en/partners/microsoft/red-hat-on-azure";
    } else if (platform === "Cloud") {
      platformUrl = "https://www.redhat.com/en/technologies/cloud-computing/openshift/cloud-services";
    } else if (platform === "Edge") {
      platformUrl = "https://www.redhat.com/en/products/edge";
    } else {
      platformUrl = "https://www.redhat.com/en/partners";
    }
    return <a href={platformUrl} target="_blank" rel="noopener noreferrer">{platform}</a>;
  }


  displayProductTag(product) {
    let productUrl;
    if (product == "ROSA") {
      productUrl = "https://www.redhat.com/en/technologies/cloud-computing/openshift/aws?intcmp=7013a00000318EWAAY";
    } else if (product == "RHEL") {
      productUrl = "https://www.redhat.com/en/technologies/linux-platforms/enterprise-linux?intcmp=7013a00000318EWAAY";
    } else if (product == "RHEL for SAP Solutions") {
      productUrl = "https://www.redhat.com/en/technologies/linux-platforms/enterprise-linux/sap?intcmp=7013a00000318EWAAY";
    } else if (product == "Ansible") {
      productUrl = "https://www.redhat.com/en/technologies/management/ansible?intcmp=7013a00000318EWAAY";
    } else if (product == "OpenShift") {
      productUrl = "https://www.redhat.com/en/technologies/cloud-computing/openshift?intcmp=7013a00000318EWAAY";
    } else if (product == "Azure OpenShift") {
      productUrl = "https://www.redhat.com/en/technologies/cloud-computing/openshift/azure?intcmp=7013a00000318EWAAY";
    } else if (product == "ACM") {
      productUrl = "https://www.redhat.com/en/technologies/management/advanced-cluster-management?intcmp=7013a00000318EWAAY";
    } else if (product == "AMQ") {
      productUrl = "https://www.redhat.com/en/technologies/jboss-middleware/amq?intcmp=7013a00000318EWAAY";
    } else if (product == "API") {
      productUrl = "https://www.redhat.com/en/technologies/jboss-middleware/3scale?intcmp=7013a00000318EWAAY";
    } else if (product == "Consulting") {
      productUrl = "https://www.redhat.com/en/services/consulting?intcmp=7013a00000318EWAAY";
    } else if (product == "Data") {
      productUrl = "https://www.redhat.com/en/technologies/cloud-computing/openshift-data-foundation?intcmp=7013a00000318EWAAY";
    } else if (product == "Identity") {
      productUrl = "https://access.redhat.com/products/identity-management?intcmp=7013a00000318EWAAY";
    } else if (product == "Insights") {
      productUrl = "https://www.redhat.com/en/technologies/management/insights?intcmp=7013a00000318EWAAY";
    } else if (product == "Application Foundations") {
      productUrl = "https://www.redhat.com/en/products/application-foundations?intcmp=7013a00000318EWAAY";
    } else if (product == "OpenStack") {
      productUrl = "https://www.redhat.com/en/technologies/linux-platforms/openstack-platform?intcmp=7013a00000318EWAAY";
    } else if (product == "ProcessAutomation") {
      productUrl = "https://www.redhat.com/en/technologies/jboss-middleware/process-automation-manager?intcmp=7013a00000318EWAAY";
    } else if (product == "Quay") {
      productUrl = "https://www.redhat.com/en/technologies/cloud-computing/quay?intcmp=7013a00000318EWAAY";
    } else if (product == "Runtimes") {
      productUrl = "https://www.redhat.com/en/products/runtimes?intcmp=7013a00000318EWAAY";
    } else if (product == "Satellite") {
      productUrl = "https://www.redhat.com/en/technologies/management/satellite?intcmp=7013a00000318EWAAY";
    } else if (product == "SmartManagement") {
      productUrl = "https://www.redhat.com/en/technologies/management/smart-management?intcmp=7013a00000318EWAAY";
    } else if (product == "Storage") {
      productUrl = "https://www.redhat.com/en/technologies/storage/ceph?intcmp=7013a00000318EWAAY";
    } else if (product == "Training") {
      productUrl = "https://www.redhat.com/en/services/training-and-certification?intcmp=7013a00000318EWAAY";
    } else if (product == "OpenShift Virtualization") {
      productUrl = "https://www.redhat.com/en/technologies/virtualization/enterprise-virtualization?intcmp=7013a00000318EWAAY";
    } else if (product == "Azure OpenShift") {
      productUrl = "https://www.redhat.com/en/technologies/cloud-computing/openshift/azure?intcmp=7013a00000318EWAAY";
    } else if (product == "Kafka") {
      productUrl = "https://developers.redhat.com/products/red-hat-openshift-streams-for-apache-kafka/overview?intcmp=7013a00000318EWAAY";
    } else if (product == "ACS") {
      productUrl = "https://www.redhat.com/en/technologies/cloud-computing/openshift/advanced-cluster-security-kubernetes?intcmp=7013a00000318EWAAY";
    } else if (product == "MS Azure") {
      productUrl = "https://azure.microsoft.com/en-us";
    } else {
      productUrl = "https://www.redhat.com/en/technologies/all-products";
    }
    return <a href={productUrl} target="_blank">{product}</a>;
  }


  displaySolutionTag(solution) {
    let solutionUrl;
    if (solution == "ApplicationDevelopment") {
      solutionUrl = "https://www.redhat.com/en/solutions/application-platform";
    } else if (solution == "Automation") {
      solutionUrl = "https://www.redhat.com/en/solutions/it-automation";
    } else if (solution == "DataScience") {
      solutionUrl = "https://www.redhat.com/en/solutions/ai";
    } else if (solution == "Edge") {
      solutionUrl = "https://www.redhat.com/en/products/edge";
    } else if (solution == "Infrastructure") {
      solutionUrl = "https://www.redhat.com/en/partners/certified-cloud-and-service-providers";
    } else if (solution == "Virtualization") {
      solutionUrl = "https://www.redhat.com/en/solutions/virtualization";
    } else {
      solutionUrl = "https://www.redhat.com/en/solutions";
    }
    return <a href={solutionUrl} target="_blank">{solution}</a>;
  }


  displayProductType(productType) {
    if (productType == "SP") {
      return "Solution Pattern";
    } else if (productType == "VP") {
      return "Validated Pattern";
    } else if (productType == "Demo") {
      return "Interactive Video Demo";
    } else if (productType == "CP") {
      return "Community Pattern";
    } else
      return "Solution Architecture";
  }


  displayProductTypeUrl(productType) {
    if (productType == "SP") {
      return "/learnmore#_solutionPattern";
    } else if (productType == "VP") {
      return "/learnmore#_validatedPattern";
    } else if (productType == "PA") {
      return "/learnmore#_buildingPA";
    } else if (productType == "Demo") {
      return "/learnmore#_interactiveVideoDemo";
    } else
      return "/learnmore";
  }


  render() {
    const { currentlist } = this.context;
    const { page, perPage, isMobileView } = this.state;
  
    // Calculate the start and end indexes for the current page
    const startIndex = (page - 1) * perPage;
    const endIndex = Math.min(startIndex + perPage, currentlist.length);
  
    // Slice the items for pagination
    const paginatedItems = currentlist.slice(startIndex, endIndex);
  
    let return_content;
  
    if (paginatedItems.length === 0) {
      return_content = (
        <React.Fragment>
          <br /> <h2>Sorry, no results found - try a different search</h2> <br />
          <Gallery hasGutter className="catalog_gallery_c">
            {/* Render blank cards for empty state */}
          </Gallery>
        </React.Fragment>
      );
    } else {
      return_content = (
        <React.Fragment>
          {/* Display the result count at the top, similar to the screenshot */}
          <div style={{ padding: '10px 0', fontSize: '1em', fontWeight: 'bold' }}>
            {`${startIndex + 1}-${endIndex} of ${currentlist.length} results`}
          </div>
  
          <Gallery hasGutter className="catalog_gallery_c">
            {paginatedItems.map(item => (
              <GalleryItem key={item.PAName}>
                <Card className="catalog_card" isHoverable={false} key={item.PAName} isCompact>
                  <CardHeader>
                    <CardHeaderMain>
                      <Link to={DETAIL_URL + '/' + item.PAName}>
                        <Brand src={CARD_IMG_URL + item.Image1Url} alt="Card Image" className="catalog_card_img" />
                      </Link>
                    </CardHeaderMain>
                  </CardHeader>
                  <CardTitle className="catalog_card_title">
                    <Link to={DETAIL_URL + '/' + item.PAName}>{item.Heading}</Link>
                    {item.isnew === "TRUE" && <Badge key={1}>New!</Badge>}
                  </CardTitle>
                  <CardBody className="catalog_card_body"><p>{item.Summary}</p></CardBody>
                  <CardFooter className={`catalog_card_footer ${isMobileView ? 'hide-tags' : ''}`}>
                    <LabelGroup numLabels={2}>

                                {/* Render Solutions tags */}
                                {item.Solutions.split(",").map(solutiontag => (
                        <Label color="cyan" key={item.ppid + solutiontag}>
                          {this.displaySolutionTag(solutiontag)}
                        </Label>
                      ))}
                      
                      {/* Render ProductType tags */}
                      {item.ProductType.split(",").map(typetag => (
                        <Label color="purple" key={item.ppid + typetag}>
                          <Link to={this.displayProductTypeUrl(typetag)}>{this.displayProductType(typetag)}</Link>
                        </Label>
                      ))}
  
            
  
                      {/* Render Platform tags if present */}
                      {item.Platform && item.Platform.split(",").map(platformtag => (
                        <Label color="blue" key={item.ppid + platformtag}>
                          {this.displayPlatformTag(platformtag)}
                        </Label>
                      ))}
  
                      {/* Render Vertical tags */}
                      {item.Vertical.split(",").map(verticaltag => (
                        <Label color="green" key={item.ppid + verticaltag}>
                          {this.displayVertical(verticaltag)}
                        </Label>
                      ))}
  
                      {/* Render Product tags */}
                      {item.Product.split(",").map(producttag => (
                        <Label color="red" key={item.ppid + producttag}>
                          {this.displayProductTag(producttag)}
                        </Label>
                      ))}
                    </LabelGroup>
                  </CardFooter>
                </Card>
              </GalleryItem>
            ))}
          </Gallery>
          
          <Pagination
            itemCount={currentlist.length} /* Total number of items */
            perPage={perPage} /* Number of items per page */
            page={page} /* Current page */
            onSetPage={this.onPageChange} /* Handler for page change */
            onPerPageSelect={this.onPerPageSelect} /* Handler for items per page */
            variant={PaginationVariant.bottom} /* Bottom pagination variant */
            className="custom-pagination"
          />
        </React.Fragment>
      );
    }
  
    return return_content;
  }
  
}

export { PACatalog };
