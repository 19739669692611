import * as React from 'react';
import {
  Page,
  PageSection,
  Grid,
  GridItem
} from '@patternfly/react-core';

import { ControlledCheckbox } from '@app/ArchitectureList/ControlledCheckbox';
import { PACatalog } from '@app/ArchitectureList/PACatalog';

import PABanner from '@app/ArchitectureList/PABanner';
import SelectedListProvider from './SelectedList';

function ArchitectureList() {

  const [isMobileView, setIsMobileView] = React.useState(false);

  const onPageResize = (props: { mobileView: boolean; windowSize: number }) => {
    setIsMobileView(props.mobileView);
  };

  const PACatalogSpan = isMobileView ? 12 : 9;

  return (
    <Page
      onPageResize={onPageResize}
    >
      <PABanner />
      <PageSection className="main_content_section">
        <SelectedListProvider>
          <Grid >
            {
              !isMobileView &&
              <GridItem span={3} rowSpan={12}>
                <PageSection className="tablepadding" id="catalog-controll">
                  <ControlledCheckbox />
                </PageSection>
              </GridItem>
            }
            <GridItem span={PACatalogSpan} rowSpan={12}>
              <PageSection>
                <PACatalog />
              </PageSection>
            </GridItem>
          </Grid>
        </SelectedListProvider>
      </PageSection>
    </Page>
  );
}

export { ArchitectureList };
