import { Helmet } from 'react-helmet';
import * as React from 'react';
import {
  Page, PageSection, Grid, GridItem, SkipToContent,
} from '@patternfly/react-core';
import { TableComposable, Thead, Tbody, Tr, Th, Td } from '@patternfly/react-table';
import '@app/react-asciidoc/fedora.css';
const CARD_IMG_URL = "/architect/portfolio/repo/images/";
const DETAIL_URL = "/architect/portfolio/detail/";
class InteractiveDemo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileView: false,
      windowSize: 0
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize(); // Set initial state
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowSize: window.innerWidth, isMobileView: window.innerWidth < 768 });
  };

  render() {
    const pageId = 'main-content-page-layout-tertiary-nav';
    const PageSkipToContent = <SkipToContent href={`#${pageId}`}>Skip to content</SkipToContent>;
    const onPageResize = (props) => {
      this.setState({ isMobileView: props.mobileView, windowSize: props.windowSize });
    };

    // Responsive Sidebar, hidden in mobile view
    let sidebarPanel = (
      <GridItem span={3} rowSpan={12}>
        <PageSection className="tablepadding">
          <TableComposable variant={'compact'} borders={false} className="pf-c-table pf-m-width-100 contribute_table">
            <Thead>
              <Tr>
                <Th colSpan="2">Jump to Sections</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td><a href="/architect/portfolio/interactive-video-demos#featured-openshift-demo">Red Hat OpenShift</a></Td>
              </Tr>
              <Tr>
                <Td><a href="/architect/portfolio/interactive-video-demos#featured-rhel-demo">Red Hat Enterprise Linux</a></Td>
              </Tr>
              <Tr>
                <Td><a href="/architect/portfolio/interactive-video-demos#featured-ansible-demo">Red Hat Ansible</a></Td>
              </Tr>
            </Tbody>
          </TableComposable>
        </PageSection>
      </GridItem>
    );

    // Hide sidebar for mobile view
    if (this.state.isMobileView) {
      sidebarPanel = null;
    }

    // Main content (top introduction)
    const topContent = (
      <div className="hero-section">
        <h1>Interactive Video Demos</h1>
        <p>Explore expert-guided video demos that transform large video data into step-by-step, clickable presentations for hands-on learning in enterprise IT solutions, helping you quickly build and enhance your skills.</p>
        <br />
        <br />
      </div>
    );

    // Events section: Video and description side by side using Grid layout with 50/50 split
    const eventsSection = (
      <><div id="featured-openshift-demo">
        <h2>Featured OpenShift Demo</h2>
        <Grid hasGutter>

          <GridItem span={this.state.isMobileView ? 12 : 6}>
            <div className="demo-video-iframe-box">
              <img
                src={`${CARD_IMG_URL}intro-marketectures/computer-vision-edge-marketing-slide.png`}
                alt="Computer Vision at the Edge Demo Marketing Slide"
                style={{ maxWidth: '100%', height: 'auto' }}
              />
            </div>
          </GridItem>

          <GridItem
  span={this.state.isMobileView ? 12 : 6}
  style={{
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: '30px'
  }}
>
  <h3>Computer Vision at the Edge</h3>
  <p>
    Explore setting up Single Node OpenShift, developing AI/ML models, and deploying them on MicroShift for real-time inference at the edge.
    <br />
    <a href={`${DETAIL_URL}62-computer-vision-edge-demo`} target="_blank">Learn more</a>
  </p>
</GridItem>

<GridItem span={this.state.isMobileView ? 12 : 6}>
  <div className="demo-video-iframe-box">
    <img
      src={`${CARD_IMG_URL}intro-marketectures/migrate-vm-marketing-slide.png`}
      alt="Virtualization Installation Trilio Virt Partner Demo Marketing Slide"
      style={{ maxWidth: '100%', height: 'auto' }}
    />
  </div>
</GridItem>

<GridItem
  span={this.state.isMobileView ? 12 : 6}
  style={{
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: '30px'
  }}
>
  <h3>Virtualization Installation Demo</h3>
  <p>
    Explore VM migration to Red Hat OpenShift Virtualization, focusing on automation, management, monitoring, and operational streamlining.
    <br />
    <a href={`${DETAIL_URL}64-migrate-vm-demo`} target="_blank">Learn more</a>
  </p>
</GridItem>

<GridItem span={this.state.isMobileView ? 12 : 6}>
  <div className="demo-video-iframe-box">
    <img
      src={`${CARD_IMG_URL}intro-marketectures/openshift-virtualization-marketing-slide.png`}
      alt="Red Hat OpenShift Virtualization Demo Marketing Slide"
      style={{ maxWidth: '100%', height: 'auto' }}
    />
  </div>
</GridItem>

<GridItem
  span={this.state.isMobileView ? 12 : 6}
  style={{
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: '30px'
  }}
>
  <h3>Red Hat OpenShift Virtualization Demo</h3>
  <p>
    Overview of OpenShift Virtualization, showing how to add virtualized applications alongside containerized workloads.
    <br />
    <a href={`${DETAIL_URL}57-openshift-virtualization-demo`} target="_blank">Learn more</a>
  </p>
</GridItem>

<GridItem span={this.state.isMobileView ? 12 : 6}>
  <div className="demo-video-iframe-box">
    <img
      src={`${CARD_IMG_URL}intro-marketectures/create-vm-rosa-gitops-marketing-slide.png`}
      alt="Create Virtual Machines in Red Hat OpenShift Service on AWS using GitOps Demo Marketing Slide"
      style={{ maxWidth: '100%', height: 'auto' }}
    />
  </div>
</GridItem>

<GridItem
  span={this.state.isMobileView ? 12 : 6}
  style={{
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: '30px'
  }}
>
  <h3>Create Virtual Machines in Red Hat OpenShift Service on AWS using GitOps Demo</h3>
  <p>
    See how virtual machines are managed in Red Hat OpenShift Service on AWS with GitOps and Red Hat OpenShift Virtualization.
    <br />
    <a href={`${DETAIL_URL}56-create-vm-rosa-using-gitops-demo`} target="_blank">Learn more</a>
  </p>
</GridItem>

<GridItem span={this.state.isMobileView ? 12 : 6}>
  <div className="demo-video-iframe-box">
    <img
      src={`${CARD_IMG_URL}intro-marketectures/event-streaming-real-time-marketing-slide.png`}
      alt="Event Streaming and Real Time Data Processing Demo Marketing Slide"
      style={{ maxWidth: '100%', height: 'auto' }}
    />
  </div>
</GridItem>

<GridItem
  span={this.state.isMobileView ? 12 : 6}
  style={{
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: '30px'
  }}
>
  <h3>Event Streaming and Real Time Data Processing Demo</h3>
  <p>
    Build an event streaming platform with Red Hat Streams for Apache Kafka. Track user activity to develop a recommendation engine and leverage real-time data processing for insights.
    <br />
    <a href={`${DETAIL_URL}61-event-streaming-real-time-data-processing-demo`} target="_blank">Learn more</a>
  </p>
</GridItem>

<GridItem span={this.state.isMobileView ? 12 : 6}>
  <div className="demo-video-iframe-box">
    <img
      src={`${CARD_IMG_URL}intro-marketectures/openshift-demo-marketing-slide.png`}
      alt="Red Hat OpenShift Container Platform Demo Marketing Slide"
      style={{ maxWidth: '100%', height: 'auto' }}
    />
  </div>
</GridItem>

<GridItem
  span={this.state.isMobileView ? 12 : 6}
  style={{
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: '30px'
  }}
>
  <h3>Red Hat OpenShift Container Platform Demo</h3>
  <p>
    Build and deploy applications using container images and Git on OpenShift. Learn how to administrate OpenShift, maximize developer experience, and deploy applications.
    <br />
    <a href={`${DETAIL_URL}58-openshift-demo`} target="_blank">Learn more</a>
  </p>
</GridItem>

<GridItem span={this.state.isMobileView ? 12 : 6}>
  <div className="demo-video-iframe-box">
    <img
      src={`${CARD_IMG_URL}intro-marketectures/virtualization-trilio-marketing-slide.png`}
      alt="Virtualization Installation Trilio Virt Partner Demo Marketing Slide"
      style={{ maxWidth: '100%', height: 'auto' }}
    />
  </div>
</GridItem>

<GridItem
  span={this.state.isMobileView ? 12 : 6}
  style={{
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: '30px'
  }}
>
  <h3>Virtualization Installation Trilio Virt Partner Demo</h3>
  <p>
    Backup, recover, upgrade, and migrate workloads with Trilio's solution for Red Hat OpenShift Virtualization.
    <br />
    <a href={`${DETAIL_URL}60-virtualization-installation-trilio-demo`} target="_blank">Learn more</a>
  </p>
</GridItem>



        </Grid>


        </div><div id="featured-rhel-demo">
  <h2>Featured Red Hat Enterprise Linux Demo</h2>
  <Grid hasGutter>
    <GridItem span={this.state.isMobileView ? 12 : 6}>
      <div className="demo-video-iframe-box">
        <img
          src={`${CARD_IMG_URL}intro-marketectures/red-hat-enterprise-linux-marketing-slide.png`}
          alt="Red Hat Enterprise Linux Demo Marketing Slide"
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      </div>
    </GridItem>

    <GridItem
      span={this.state.isMobileView ? 12 : 6}
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
        marginBottom: '30px'
      }}
    >
      <h3>Red Hat Enterprise Linux Demo</h3>
      <p>
        Learn how to easily migrate to Red Hat Enterprise Linux. Understand the business and technical reasons for making the switch.<br />
        <a href={`${DETAIL_URL}59-red-hat-enterprise-linux-demo`} target="_blank">Learn more</a>
      </p>
    </GridItem>

    <GridItem span={this.state.isMobileView ? 12 : 6}>
      <div className="demo-video-iframe-box">
        <img
          src={`${CARD_IMG_URL}intro-marketectures/instruct-lab-marketing-slide.png`}
          alt="InstructLab Demo Marketing Slide"
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      </div>
    </GridItem>

    <GridItem
      span={this.state.isMobileView ? 12 : 6}
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
        marginBottom: '30px'
      }}
    >
      <h3>InstructLab Demo</h3>
      <p>
        InstructLab simplifies LLM training and alignment, allowing users to contribute through synthetic data generation and tuning via the ilab CLI. An open-source project that makes AI model improvement accessible to everyone.<br />
        <a href={`${DETAIL_URL}63-instructlab-demo`} target="_blank">Learn more</a>
      </p>
    </GridItem>
  </Grid>
</div>

<div id="featured-ansible-demo">
  <h2>Featured Red Hat Ansible Demo</h2>
  <Grid hasGutter>
    <GridItem span={this.state.isMobileView ? 12 : 6}>
      <div className="demo-video-iframe-box">
        <img
          src={`${CARD_IMG_URL}intro-marketectures/ansible-lightspeed-trial-marketing-slide.png`}
          alt="Ansible Lightspeed 90-day Trial Demo Marketing Slide"
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      </div>
    </GridItem>

    <GridItem
      span={this.state.isMobileView ? 12 : 6}
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
        marginBottom: '30px'
      }}
    >
      <h3>Ansible Lightspeed 90-day Trial Demo</h3>
      <p>
        Automate easily with Ansible Lightspeed. Create task recommendations, generate playbooks, and view playbook explanations with your trial account.<br />
        <a href={`${DETAIL_URL}55-ansible-lightspeed-trial-demo`} target="_blank">Learn more</a>
      </p>
    </GridItem>
  </Grid>



        </div></>


    );

    // Combine top content and events section using the grid system
    const mainContent = (
      <GridItem span={this.state.isMobileView ? 12 : 9}>
        <PageSection>
          {topContent}
          {eventsSection}
        </PageSection>
      </GridItem>
    );

    return (
      <React.Fragment>
        <Helmet>
          <title>Red Hat Architecture Center - Interactive Video Demos</title>
          <meta
            name="description"
            content="Discover expert-guided demos for Red Hat OpenShift, Ansible Automation, Red Hat Enterprise Linux (RHEL), and more. Gain hands-on experience through step-by-step tutorials, detailed video walkthroughs, and expert-led guides to help you master hybrid cloud infrastructure, application services, cloud-native application development, and automation solutions"
          />
          <meta
            name="keywords"
            content="interactive demos, enterprise IT solutions, hands-on IT learning, expert-guided demos, IT skills development, step-by-step tutorials, automation, hybrid cloud platforms, video walkthroughs"
          />
          <meta
            property="og:title"
            content="Red Hat Architecture Center - Interactive Video Demos"
          />
          <meta
            property="og:description"
            content="Explore expert-guided demos for Red Hat OpenShift, Ansible Automation, Red Hat Enterprise Linux (RHEL), and more. Gain hands-on experience through step-by-step tutorials and detailed video walkthroughs."
          />
        </Helmet>
        <Page
          isManagedSidebar
          skipToContent={PageSkipToContent}
          mainContainerId={pageId}
          onPageResize={onPageResize}
        >
          <PageSection className="main_content_section_info">
            <Grid hasGutter>
              {sidebarPanel}
              {mainContent}
            </Grid>
          </PageSection>
        </Page>
      </React.Fragment>
    );
  }
}

export { InteractiveDemo };